.sig-controls {
  // position: absolute;
  margin: 5px;
}

.sig-canvas {
  border: 1px solid rgb(215 213 211);
  width: 260px;
  height: 260px;
  background-color: rgb(239 237 235);
}
