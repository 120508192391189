.Subquestion {
  margin-top: 18px;
  margin-left: 20px;
}

.SubquestionText {
  margin-top: 2px;
  margin-left: 25px;
}

.dailytaskQ {
  width: 100%;
  margin: 10px auto;

  .dailytaskQDivMain {
    .dailytaskQDiv {
      display: flex;
      margin: 15px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .dailytaskQDel {
        width: 70px;
        justify-content: center;
        align-items: center;

        img {
          width: 24px;
        }
      }
    }

    .form-label:empty {
      display: none;
    }
  }
}

span.icon-info {
  font-size: 14px;
  line-height: 17px;
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 1px solid #666;
  text-align: center;
  border-radius: 50%;
  color: #666;
  font-weight: 600;
  margin-left: 6px;
}

.lineforppe {
  border: 1px dashed #333;
  width: 1115px;

  height: 130px;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
}

.LineBreak {
  border: 1px solid rgb(168, 162, 162);
  margin-top: 20px;
  margin-bottom: 15px;
  width: 80vw;
}

.ExampleImageinPPE {
  position: relative;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  bottom: 40px;
}

.caption {
  font-family: "EricssonHilda-Regular";

  color: #f50713;

  font-weight: bold;
  text-align: center;
}

.ImageProperties {
  border: rgb(73, 72, 72);
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
  margin-top: 2px;
  width: auto;
}

// @media only screen and (max-width: 600px) {
//     .ExampleImageinPPE {
//         position: relative;
//         width: 100%;
//         height: 100%;
//         text-align: right;

//     }
// }

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {
//     .ExampleImageinPPE {
//         position: relative;
//         width: 100%;
//         height: 100%;
//         text-align: right;

//     }
// }

/* Medium devices (landscape tablets, 768px and up) */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .ExampleImageinPPE {
    position: relative;

    height: 100%;
    text-align: right;
    bottom: 0px;
  }
}

@media only screen and (min-width: 768px) {
  .ExampleImageinPPE {
    position: relative;

    height: 100%;
    text-align: right;
    bottom: 5px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .ExampleImageinPPE {
    position: relative;

    height: 100%;
    text-align: right;
    bottom: 5px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .ExampleImageinPPE {
    position: relative;

    height: 100%;
    text-align: right;
  }
}

@media only screen and (min-width: 1400px) {
  .ExampleImageinPPE {
    position: relative;

    height: 100%;
    text-align: right;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .ImageProperties {
    position: relative;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .ImageProperties {
    position: relative;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .ImageProperties {
    position: relative;
  }
}

@media only screen and (min-width: 1400px) {
  .ImageProperties {
    position: relative;
  }
}

// @media only screen and (max-width: 600px) {
//     .caption {

//         font-family: "EricssonHilda-Regular";

//         position: relative;
//         width: 100%;
//         height: 100%;
//         text-align: center;

//     }
// }

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {
//     .caption {

//         font-family: "EricssonHilda-Regular";
//         position: relative;
//         width: 100%;
//         height: 100%;
//         text-align: center;

//     }
// }

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .caption {
    text-align: center;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .caption {
    font-family: "EricssonHilda-Regular";
    overflow-wrap: normal;
    text-align: center;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .caption {
    font-family: "EricssonHilda-Regular";

    text-align: center;
    overflow-wrap: normal;
  }
}

@media only screen and (max-width: 1400px) {
  .caption {
    font-family: "EricssonHilda-Regular";

    text-align: center;
    font-size: 0.875em;
  }
}

.ImageProps {
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: 50%;

  @media only screen and (max-width: 991px) {
    width: 100%;
  }
}
