@import "./assets/scss/variables.scss";
@import "./assets/scss/mixin.scss";

* {
  margin: 0;
  font-family: $font-ericssonHilda-regular;
  font-size: 14px;
  // color: #fff;
}

body {
  background-color: #fdfbf9;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

/*scroll-bar style*/
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $background-scroll-color;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// global form style
.form-select-css {
  @include form-select-mixin(250px);
  width: 2px;
}

.checking1 {
  border-width: 2px;
}

.form-email-css {
  @include form-select-mixin(350px);
  width: 2px;
}

.btn {
  border-radius: 0;
}

.no-border-input {
  border-radius: 0;
}

.from-select-css {
  @include form-select-mixin(176.5px);
  width: 2px;
}

.report-heading-1 {
  line-height: 1.5em;
  font-size: 14px;
  margin: 10px;
  font-family: $font-ericssonHilda-bold;
}

// .icon-profile {
//   background: url('./Asset/Icons/profile.svg');
//   height: 20px;
//   width: 20px;
//   display: block;
//   /* Other styles here */
// }

h4 {
  color: $h4-color;
  font-family: $font-ericssonHilda-bold;
}

// datepicker style
.react-datepicker-wrapper {
  .react-datepicker__input-container {
    width: auto !important;

    @media only screen and (max-width: 440px) {
      width: 100% !important;
    }
  }
}

button.react-datepicker__close-icon::after {
  background-color: $button-react-datepicker;
}

/* table pagination style */
.react-bs-table-sizePerPage-dropdown.dropdown {
  .dropdown-toggle {
    border-radius: 0 !important;
    margin-right: 5px;
    background-color: $font-white-color;
    color: $background-color;
    border: 1px solid #ced4da;
  }
}

.page-item:first-child {
  .page-link {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.page-item:last-child {
  .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.page-item {
  .page-link {
    // background-color: $background-color;
    // color: $font-white-color;
    // @include background-font-color-mixin;
    border: 1px solid #ced4da;
  }

  .page-link:hover {
    z-index: 2;
    // border-color: $font-white-color  !important;
    // @include background-font-color-mixin($font-white-color  !important, $black-background);
    font-family: $font-ericssonHilda-regular;
    // color: #0d6efd;
    // background-color: $font-white-color !important;
    // color: $black-background;
    //font-weight: 600;
  }
}

.page-item.disabled {
  .page-link {
    // @include background-font-color-mixin($background-color, $page-item-disabled-color);
    border: 1px solid #ced4da;
    // background-color: $background-color;
    // color: $page-item-disabled-color;
  }
}

.react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: right;
}

// .page-item.disabled .page-link {
//   background-color: $background-color;
//   color: $page-item-disabled-color;
//   border: 0;
// }

// .page-item .page-link {
//   background-color: $background-color;
//   color: $font-white-color;
//   border: 0;
// }

.rangeselector > .button > .selector-text {
  fill: $black-background !important;
}

.icon-white-color {
  filter: brightness(0) invert(1);
}

.icon-primary-color {
  filter: invert(36%) sepia(67%) saturate(5710%) hue-rotate(212deg)
    brightness(105%) contrast(106%);
}

.icon-red-color {
  filter: invert(29%) sepia(21%) saturate(4708%) hue-rotate(325deg)
    brightness(104%) contrast(102%);
}

/* sidebar icons */
.sidebar-icons {
  width: 20px;
  /* top: 6px; */
  margin-right: 18px;
  margin-bottom: 3px;
}

.icon-attachment {
  width: 90%;
  height: 31px;
  margin-left: 10px;
  margin-bottom: 0px; // background-color: grey;
  margin-top: auto;
}

.file-wrapper {
  display: block;
  // height: 37px;
  // margin-top: -17px;
  // width: 100%;
  // height: 16vh;
  // margin-left: 8px;

  margin-top: auto;
}

.download-button {
  height: 5.7vh;
  margin-left: 8px;
  width: 100%;
}

.TextofLink {
  white-space: nowrap;
  margin-left: 2px;

  margin-bottom: 10px;
  font-family: "EricssonHilda-Bold";
  font-size: 11px;
}

/* loading css */
.loading-css {
  width: 32px;
  height: 32px;
  -webkit-animation: 2s ease-in-out infinite rotateAnimation;
  animation: 2s ease-in-out infinite rotateAnimation;
  position: absolute;
  /*  background-image: url('./Asset/Icons/loading.svg'); */
}

@media (prefers-reduced-motion: no-preference) {
  .loading-logo {
    animation: loading-logo-spin infinite 1s ease-in-out;
    /* width: 28px;
    height: 28px; */
  }
}

.loading-container {
  text-align: center;
  /* padding: 100px; */
}

@keyframes loading-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// file input dashed
.custom-file-input {
  // color: transparent;
  padding: 20px;

  font-family: $font-ericssonHilda-regular;
  border-width: 2px;
  color: rgba(0, 0, 0, 0);
  font-size: 0;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  // content: 'Select some files';
  color: black;

  // padding: 20px;

  content: "Click Here to Add Photo";

  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px;

  text-shadow: 1px 1px #fff;
  font-weight: 600;
  font-size: 10pt;

  @media (max-width: 420px) {
    width: 25vw;
    font-size: 9px;
  }

  @media (max-width: 360px) {
    width: 23vw;
    font-size: 7px;
  }

  @media (max-width: 390px) {
    width: 24vw;
    font-size: 7px;
  }

  @media (max-width: 300px) {
    width: 28vw;
    font-size: 7px;
  }
}

.custom-file-input:active {
  outline: 0;
}

// .custom-file-input:active::before {
//   background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
// }

// margin -css
.margin-container {
  margin-top: 65px;
  margin-bottom: 125px;
}

.modal-form-1 {
  width: 17em;
  line-height: 1.8em;
  border-radius: 0;
}

//hide typing cursor(blinking)
.hide-blinking-cursor {
  caret-color: transparent;
}

.pointer {
  cursor: pointer;
}

// button.react-datepicker__close-icon::after {
//   width: 8em;
// }
//table
.search-label-report {
  line-height: 2.5em;
}

// .modal-form-1.react-datepicker-ignore-onclickoutside {
//   font-size: 14px;
//   border: 2px solid #767676;
// }

// datepicker style
// .react-datepicker-wrapper {
//   .react-datepicker__input-container {
//     width: auto !important;
//   }
// }

// .react-datepicker-wrapper {
//   display: block !important;
// }

// button.react-datepicker__close-icon::after {
//   background-color: $button-react-datepicker
// }

.img-size_preview {
  width: 8em;
}

@media only screen and (min-width: 600px) {
  .modal-form-1 {
    // width: 32em;
    line-height: 1.8em;
    border-radius: 0;
  }
}

.multiSelectCheckBox {
  position: relative;
  display: inline-block;
  .multiSelectDropDown.rmsc {
    width: 17em;
    border-color: rgb(0, 0, 0);
    border-style: solid;
    border-width: 1.56px;

    @media only screen and (max-width: 440px) {
      width: 100%;
    }

    .dropdown-container {
      border-radius: 0px;

      .dropdown-heading {
        height: 25.4px;

        .clear-selected-button {
          display: none;
        }

        .dropdown-heading-value {
          span {
            color: #000;
          }
        }
      }

      .dropdown-content {
        z-index: 2;

        .search {
          display: none;
        }

        .item-renderer {
          color: #000;
        }

        .options {
          .select-item {
            &.selected {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

.Reports{
  .multiSelectCheckBox{width: 100%;}
}

// Common Loader
.loading-container {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(255, 255, 255, 0.5);
}

.focused {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.focusedOut {
  box-shadow: none !important;
}

.text-danger.fontSize {
  font-size: 14px;
}

.Reports {
  .react-datepicker-wrapper {
    input[type="text"] {
      border: 1px solid #000;
      padding: 0 5px;
      outline: none;

      @media only screen and (max-width: 440px) {
        width: 100%;
      }
    }
  }

  .reportEdit > * {
    margin: 0 1px;
  }

  .naTag {
    margin: 0 18px;
  }

  .multiSelectCheckBox {
    .multiSelectDropDown.rmsc {
      border: 0;
      outline: none;

      .dropdown-container {
        border: 1px solid #000;
        outline: none;
        box-shadow: none;
      }
    }
  }

  .react-bootstrap-table {
    width: 100%;
    overflow-x: auto;

    table.table.table-hover.table-css {
      min-width: 1000px;

      th {
        border-bottom: 2px solid black;
        background-color: inherit;
      }

      td {
        background-color: inherit;
      }
    }
  }

  .reportDate {
    > div {
      @media only screen and (max-width: 991px) {
        margin-bottom: 15px;
      }
    }
  }
}

.expand-cell-header {
  pointer-events: none;
}

// Author:- Roshan Ghosalkar
// Date:-01Nov2022
// Desc:- As discuss with @Ali as per requirement
#gMapCheck {
  height: 400px;
  width: 100%;
}

.customTxtDiv {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;

  #gMapTextBox {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 350px;
    height: 40px;
    padding: 0 7px;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
  }
}

@media only screen and (max-width: 576px) {
  #gMapCheck {
    height: 450px;
  }

  .customTxtDiv {
    > div {
      width: 100%;

      #gMapTextBox {
        width: 100%;
      }
    }
  }
}

.gmapModal {
  @media only screen and (min-width: 576px) {
    .modal-dialog {
      max-width: 700px;
    }
  }
}

.pac-container {
  z-index: 100000;
}

.gmapTextArea {
  border: 1px transparent;
  padding: 5px 0;
}

// End

.accordHeader {
  .accordion-button {
    background-color: #efefef;
    color: #333;
    box-shadow: none;
  }
}

.topToBottom {
  top: 90px;

  .icon-white-color {
    transform: rotate(180deg);
  }
}

.imgDiv {
  border: 1px dashed #333;
  max-width: 130px;
  height: 130px;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;

  .removeImg {
    position: absolute;
    right: 0;
    top: 0;
    background: #333;
    color: #fff;
    width: 17px;
    cursor: pointer;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
}

.imgDiv1 {
  border: 1px solid #fc0808;
  max-width: 130px;
  height: 130px;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;

  .removeImg {
    position: absolute;
    right: 0;
    top: 0;
    background: #333;
    color: #fff;
    width: 17px;
    cursor: pointer;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
}

.btnSupport {
  position: fixed;
  right: 35px;
  bottom: 70px;

  @media only screen and (max-width: 440px) {
    right: 15px;
  }

  @media only screen and (max-width: 300px) {
    right: 5px;
  }

  .supportLink {
    color: #fff;

    @media only screen and (max-width: 440px) {
      padding: 0.5rem 0;
    }
  }
}

.HazardsDiv {
  .addOtherHazards {
    display: flex;
    justify-content: flex-end;
    padding-top: 0 !important;
  }

  .Hazards {
    margin-bottom: 40px;

    .form-label {
      width: 100%;
      display: flex;
      align-items: center;

      .HazardLabel {
        background: transparent;
        border: 1px solid transparent;
        width: calc(100% - 90px);
        border-radius: 4px;
        outline: none;
        padding: 0.375rem 0;
        font-size: 1rem;
        color: #212529;

        &.activeLabel {
          background: #fff;
          border: 1px solid #ced4da;
          width: 250px;
          padding: 0.375rem 0.75rem;

          @media only screen and (max-width: 576px) {
            width: 170px;
          }
        }
      }

      .edit {
        color: #363636;
        cursor: pointer;
        font-size: 16px;
        border: 1px solid #363636;
        width: 25px;
        height: 25px;
        margin-right: 5px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .check {
        margin: 0 5px;
        cursor: pointer;
        color: #03f906;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #03f906;
        background: #fff;
      }

      .close {
        margin: 0 5px;
        cursor: pointer;
        color: #363636;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #363636;
        background: #fff;
      }
    }
  }
}

.customCol {
  @media only screen and (max-width: 991px) {
    max-width: 50%;
    flex: 0 0 50%;
  }

  @media only screen and (max-width: 576px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.selectionProcess {
  display: flex;
  justify-content: center;

  .wrapperIn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    flex-wrap: wrap;

    .checkDiv {
      width: 150px;
      font-size: 18px;
      background: #a5cbd8;
      margin: 20px 40px;
      height: 150px;
      display: flex;
      justify-content: center;
      flex-flow: column;
      align-items: center;
      color: #135d76;
      box-shadow: 0 5px 10px #999;
      border-radius: 10px;
      cursor: pointer;

      img {
        margin-bottom: 10px;
        width: 32px;
      }
    }
  }
}

.inProgress {
  position: fixed;
  cursor: pointer;
  width: 250px;
  bottom: 50px;
  left: 35px;
  z-index: 4;
  animation: bounce 1s ease-in infinite;
  perspective: 40px;

  @media only screen and (max-width: 440px) {
    left: 15px;
  }

  @media only screen and (max-width: 390px) {
    left: 15px;
    width: 200px;
  }

  @media only screen and (max-width: 300px) {
    left: 5px;
    width: 160px;
  }

  .alert-success {
    color: #000;
    background-color: #ffc88b;
    border-color: #ff8c0a;
  }

  .shadowAnimation {
    position: fixed;
    width: 200px;
    height: 15%;
    opacity: 0.5;
    box-shadow: 0 0 5px #666;
    background: #666;
    left: 25px;
    bottom: -5px;
    border-radius: 50%;
    z-index: 4;

    &.scale {
      animation: scale 1s ease-in infinite;
    }

    @media only screen and (max-width: 390px) {
      width: 145px;
    }

    @media only screen and (max-width: 300px) {
      width: 135px;
      height: 10%;
    }
  }

  .inProgressCount {
    font-family: "EricssonHilda-Bold", Helvetica, Arial, sans-serif;
  }
}

@keyframes bounce {
  50% {
    transform: translateY(-10px);
  }
}

@keyframes scale {
  50% {
    transform: scale(0.9);
  }
}

.btnClick {
  display: flex;

  .link {
    margin: 0 3px;
    background-color: #f8f9fa;
    width: 34px;
    height: 28px;
    border-color: #f8f9fa;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-call {
      font-size: 22px;
      color: #333;
      transform: rotate(30deg);
    }
  }

  button {
    margin: 0 3px;
  }
}

.updateCrew {
  text-decoration: underline;
  color: #212529;
  cursor: pointer;

  &:hover {
    color: #0d6efd;
  }
}

.container12 {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.form12 {
  flex-basis: 50%;
  margin-right: 25px;
  padding: 20px;
  // width: 100%;
  overflow-y: auto;
  height: 100%;
}

.video.call12 {
  flex-grow: 1;
  background-color: #f1f1f1;
  height: 100%;
}

.video-call-container {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  height: 170%;
  // margin-top: 35px;
}

.sessionPopup {
  position: fixed;
  display: flex;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  z-index: 12;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    background: #fff;
    padding: 20px;

    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .body {
      margin-top: 10px;
    }
    .bottom {
      text-align: right;
      margin-top: 10px;
      .btn.warning {
        background: #ff0000;
        border-radius: 5px;
        color: #fff;
      }
      .btn.primary {
        background: #007bff;
        border-radius: 5px;
        color: #fff;
      }
    }
  }
}
.toast_message {
  position: fixed;
  cursor: pointer;
  width: 250px;
  top: 35px;
  right: 10px;
  z-index: 100;
  perspective: 40px;

  @media only screen and (max-width: 440px) {
    left: 15px;
  }

  @media only screen and (max-width: 390px) {
    left: 15px;
    width: 200px;
  }

  @media only screen and (max-width: 300px) {
    left: 5px;
    width: 160px;
  }

  .alert-success {
    color: #000;
    background-color: #ffc88b;
    border-color: #ff8c0a;
  }

  .shadowAnimation {
    position: fixed;
    width: 200px;
    height: 15%;
    opacity: 0.5;
    box-shadow: 0 0 5px #666;
    background: #666;
    left: 25px;
    bottom: -5px;
    border-radius: 50%;
    z-index: 4;

    &.scale {
      animation: scale 1s ease-in infinite;
    }

    @media only screen and (max-width: 390px) {
      width: 145px;
    }

    @media only screen and (max-width: 300px) {
      width: 135px;
      height: 10%;
    }
  }

  .inProgressCount {
    font-family: "EricssonHilda-Bold", Helvetica, Arial, sans-serif;
  }
}

img.icon-info {
  width: 18px;
  height: 18px;
  margin-left: 4px;
}

// file input dashed
.custom-attachment-input {
  // color: transparent;
  padding: 20px;

  font-family: $font-ericssonHilda-regular;
  border-width: 2px;
  color: rgba(0, 0, 0, 0);
  font-size: 0;
}

.custom-attachment-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-attachment-input::before {
  // content: 'Select some files';
  color: black;

  // padding: 20px;

  content: "Click Here to Add Attachment";

  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px;

  text-shadow: 1px 1px #fff;
  font-weight: 600;
  font-size: 10pt;

  @media (max-width: 420px) {
    width: 25vw;
    font-size: 9px;
  }

  @media (max-width: 360px) {
    width: 23vw;
    font-size: 7px;
  }

  @media (max-width: 390px) {
    width: 24vw;
    font-size: 7px;
  }

  @media (max-width: 300px) {
    width: 28vw;
    font-size: 7px;
  }
}

.custom-attachment-input:active {
  outline: 0;
}

.texthref {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(177, 177, 177, 0.5);
  z-index: 999;
  pointer-events: none;
}
.crewMemControls{
  .customCol{
    .form-select{height: 34px;}
    .css-b62m3t-container{min-height: 34px;
      .css-t3ipsp-control{border: 1px solid #dee2e6; min-height: 34px; box-shadow: none; border-radius: 0;
        &:hover{border-radius: 0; border-color: #dee2e6;}
        .css-15lsz6c-indicatorContainer{padding:6px;}
      }
      .css-13cymwt-control{min-height: 34px; border-radius: 0;
        .css-1fdsijx-ValueContainer{padding: 0 8px;}
        .css-hlgwow{padding: 1px 8px;}
      }
    }
      .css-1xc3v61-indicatorContainer{padding: 6px;}   
  }
}