.Submitbutton {
    text-align: left;
}

.CertificateSec {
    margin-bottom: 25px;
}

.certifyDiv {
    display: flex;
    justify-content: flex-start;
    margin-top: 35px;
    height: 10px;

    transform: translateY(-40px);
    -webkit-transition: translateY(-40px);
    -moz-transition: translateY(-40px);
    -o-transition: translateY(-40px);
}

.certifyDiv .certifyLink {
    width: 46px;
    background: #0d6efd;
    color: #fff;
    height: 42px;
    margin-left: 10px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.certifyDiv .certifyLink .certifyImg {
    border: 1px solid rgb(255 255 255) !important;

    cursor: pointer;
    background: #fff;
}