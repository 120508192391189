.Main1 {
  min-height: 50vh;
  height: auto;
  background-color: rgb(247, 243, 243);
  width: auto;
  background-size: cover;
  padding-top: 25px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 13px;
}

.box-css {
  height: auto;
  width: (height/2);
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  z-index: 9999;
  padding: 20px;
  border-radius: 12px;
}

.Main12 {
  background-color: rgb(247, 243, 243);
  font-weight: 1000;

  text-align: center;
  color: black;
  font-size: 19px;
}

.dropdown1 {
  width: 10vw;
  height: auto;
  text-align: center;
  border-radius: 12px;
  border: 0px;
  margin: 20px;
  padding: 6px;
}

.wrapper {
  text-align: center;
  margin-top: 45px;
  margin-right: 155px;
  width: auto;
  height: auto;
}

.button1 {
  position: absolute;

  justify-content: center;
  width: auto;
  height: auto;
  background-color: green;
  color: black;
  margin-left: 25px;
  border-radius: 1px;
}

.form-group1 {
  margin-top: 15px;
  margin-right: 35px;
}

.heading1 {
  color: rgb(0, 0, 0);
}

.descriptionbox {
  height: 20vh;
}

.image-preview {
  width: 12vw;
  height: 14vh;
  float: left;

  padding: 5px;
}

.custom-datecss {
  width: 100% !important;
  justify-content: space-between;
  min-height: 38px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.removeImg1 {
  position: absolute;
  right: 0;
  top: 0;
  background: #333;
  color: #fff;
  border: "none";
  width: 10px;
  height: max-content;
  cursor: pointer;
  text-align: center;
}

.ImageDiv {
  position: relative;
  display: inline-block;
  border: 1px dashed #333;
  align-items: center;
  margin: 2px;
  justify-content: center;
}
