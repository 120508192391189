.Subquestion {
  margin-top: 18px;
  margin-left: 20px;
}

.SubquestionText {
  margin-top: 2px;
  margin-left: 25px;
}

.dailytaskQ {
  width: 100%;
  margin: 10px auto;

  .dailytaskQDivMain {
    .dailytaskQDiv {
      display: flex;
      margin: 15px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .dailytaskQDel {
        width: 70px;
        justify-content: center;
        align-items: center;

        img {
          width: 24px;
        }
      }
    }

    .form-label:empty {
      display: none;
    }
  }
}

span.icon-info {
  font-size: 14px;
  line-height: 17px;
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 1px solid #666;
  text-align: center;
  border-radius: 50%;
  color: #666;
  font-weight: 600;
  margin-left: 6px;
}

.lineforppe {
  border: 1px dashed #333;
  width: 1115px;

  height: 130px;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
}

.LineBreak {
  border: 1px solid rgb(168, 162, 162);
  margin-top: 20px;
  margin-bottom: 15px;
  width: 80vw;
}

.buttonDownload {
  display: inline-block;
  position: relative;
  padding: 8px 25px;
  margin-left: 5px;
  background-color: #4e4e4e;
  color: white;
  width: 100%;
  font-family: sans-serif;
  text-decoration: none;
  font-size: 0.9em;
  text-align: center;
  text-indent: 15px;
  height: 100%;
}

.buttonDownload:hover {
  background-color: #333;
  color: white;
}

.buttonDownload:before,
.buttonDownload:after {
  content: " ";
  display: block;
  position: absolute;
  left: 15px;
  top: 52%;
}

/* Download box shape  */
.buttonDownload:before {
  width: 10px;
  height: 2px;
  border-style: solid;
  border-width: 0 2px 2px;
}

/* Download arrow shape */
.buttonDownload:after {
  width: 0;
  height: 0;
  margin-left: 3px;
  margin-top: -7px;

  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: transparent;
  border-top-color: inherit;

  animation: downloadArrow 2s linear infinite;
  animation-play-state: paused;
}

.buttonDownload:hover:before {
  border-color: #010201;
}

.buttonDownload:hover:after {
  border-top-color: #070707;
  animation-play-state: running;
}

/* keyframes for the download icon anim */
@keyframes downloadArrow {
  /* 0% and 0.001% keyframes used as a hackish way of having the button frozen on a nice looking frame by default */
  0% {
    margin-top: -7px;
    opacity: 1;
    margin-left: 1px;
  }

  0.001% {
    margin-top: -15px;
    opacity: 0;
    margin-left: 1px;
  }

  50% {
    opacity: 1;
    margin-left: 1px;
  }

  100% {
    margin-top: 0;
    opacity: 0;
    margin-left: 1px;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-out {
  animation-name: fadeOut;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.image-allignment {
  margin-left: 190px;
  margin-top: -75px;
}

.TextofCertify {
  margin-top: 12px;
}
