.Multiselect12 {
  height: 10vh;
}

@media (max-width: 440px) {
  .Reports .react-datepicker-wrapper input[type="text"] {
    width: 100%;
    max-width: 100%;
  }
}
