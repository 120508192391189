.form-subheading-1 {
  color: #ff8c0a;
  font-family: "EricssonHilda-Bold", Helvetica, Arial, sans-serif;
  font-size: large;
}

#sig-canvas {
  border: 1px solid #cccccc;
  border-radius: 15px;
  cursor: crosshair;
}

.filter-green {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg)
    brightness(118%) contrast(119%);
}

.filter-red {
  filter: invert(38%) sepia(98%) saturate(4682%) hue-rotate(338deg)
    brightness(91%) contrast(88%);
}

.filter-warn {
  filter: invert(68%) sepia(83%) saturate(582%) hue-rotate(353deg)
    brightness(102%) contrast(102%);
}

.bold-font {
  font-weight: 600;
}

// .horizontal-scrollable>.hs-cols {
//     overflow-x: auto;
//     white-space: nowrap;
// }

// .horizontal-scrollable>.hs-cols>.p-2 .bd-highlight {
//     display: inline-block;
//     float: none;
// }

.wrapper-w {
  display: flex;
  overflow-x: auto;
}

.wrapper-w::-webkit-scrollbar {
  // width: 0px !important;
  display: none;
}

.ExampleImageinPPE {
  position: relative;
  width: 100%;
  height: 100%;
  align-items: flex-start;
}

.caption {
  font-family: "EricssonHilda-Regular";

  color: #f50713;

  font-weight: bold;
  text-align: center;
}

.ImageProperties {
  border: rgb(73, 72, 72);
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
  margin-top: 2px;
  width: auto;
}

// @media only screen and (max-width: 600px) {
//     .ExampleImageinPPE {
//         position: relative;
//         width: 100%;
//         height: 100%;
//         text-align: right;

//     }
// }

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {
//     .ExampleImageinPPE {
//         position: relative;
//         width: 100%;
//         height: 100%;
//         text-align: right;

//     }
// }

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .ExampleImageinPPE {
    position: relative;

    height: 100%;
    text-align: right;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .ExampleImageinPPE {
    position: relative;

    height: 100%;
    text-align: right;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .ExampleImageinPPE {
    position: relative;

    height: 100%;
    text-align: right;
  }
}

@media only screen and (min-width: 1400px) {
  .ExampleImageinPPE {
    position: relative;

    height: 100%;
    text-align: right;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .ImageProperties {
    position: relative;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .ImageProperties {
    position: relative;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .ImageProperties {
    position: relative;
  }
}

@media only screen and (min-width: 1400px) {
  .ImageProperties {
    position: relative;
  }
}

// @media only screen and (max-width: 600px) {
//     .caption {

//         font-family: "EricssonHilda-Regular";

//         position: relative;
//         width: 100%;
//         height: 100%;
//         text-align: center;

//     }
// }

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {
//     .caption {

//         font-family: "EricssonHilda-Regular";
//         position: relative;
//         width: 100%;
//         height: 100%;
//         text-align: center;

//     }
// }

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .caption {
    text-align: center;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .caption {
    font-family: "EricssonHilda-Regular";
    overflow-wrap: normal;
    text-align: center;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .caption {
    font-family: "EricssonHilda-Regular";

    text-align: center;
    overflow-wrap: normal;
  }
}

@media only screen and (max-width: 1400px) {
  .caption {
    font-family: "EricssonHilda-Regular";

    text-align: center;
    font-size: 0.875em;
  }
}

.ImageProps {
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: 50%;

  @media only screen and (max-width: 991px) {
    width: 100%;
  }
}

.crewMemAccord {
  display: flex;
  flex-wrap: wrap;

  .accordion-header {
    width: calc(100% - 70px);

    @media only screen and (max-width: 768px) {
      width: calc(100% - 40px);

      .accordion-button {
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;

          .filter-red {
            margin-left: auto !important;
          }

          .badge {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .deleteCrewDiv {
    width: 70px;
    justify-content: center;
    align-items: center;
    border-bottom: 0;

    @media only screen and (max-width: 768px) {
      width: 40px;
    }

    .icon-red-color {
      width: 24px;
    }
  }

  .accordion-collapse {
    width: 100%;
  }

  .accordion-collapse.collapse.show + .deleteCrewDiv {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
}

.Textwithcolor {
  font-family: "EricssonHilda-Regular", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #dc2d37;
}

.dailytaskQ {
  width: 100%;
  margin: 10px auto;
  .dailytaskQDivMain {
    .dailytaskQDiv {
      display: flex;
      margin: 15px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .dailytaskQDel {
        width: 70px;
        justify-content: center;
        align-items: center;
        img {
          width: 24px;
        }
      }
    }
    .form-label:empty {
      display: none;
    }
  }
}

span.icon-info {
  font-size: 14px;
  line-height: 17px;
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 1px solid #666;
  text-align: center;
  border-radius: 50%;
  color: #666;
  font-weight: 600;
  margin-left: 6px;
}
