@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixin.scss";

.footer {
  @include background-font-color-mixin(#212529);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  line-height: normal;
  .footerLink{ color: #fff; margin: 0 5px; text-decoration: none;
    &:hover{text-decoration: underline;}
  }
}

.nav-content {
  padding: 10px 0;
}
