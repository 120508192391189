@import "./variables.scss";

@mixin form-select-mixin($w, $br:0) {
    width: $w;
    border-radius: $br;
}

@mixin background-font-color-mixin($bg:$background-color, $fcolor:$font-white-color) {
    background-color: $bg;
    color: $fcolor;
}

@mixin alert-class($color-txt, $ml:0) {
    width: 34%;
    border: 1px solid $color-txt;
    line-height: 1.5em;
    margin-left: $ml;
    margin-right: 8px;
    margin-top: 25px;
}