.card-custom {
  width: 450px;
  margin: 50px;
}

.card-custom-img {
  background-color: #facc01;
}

.card-header-custom {
  background-color: #f8f9fa;
  border: 0;
}

.card-header-custom1 {
  background-color: #ffffff;
  border: 0;
}

h3 {
  font-family: "EricssonHilda-Bold", Helvetica, Arial, sans-serif;
}

.watermark-logo {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
}

.watermark-logo::after {
  content: "";
  background: url(../../assets/Icons/sw_bg.jpg);
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;
  // height: 100vh;
  // width: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.privacy {
  font-size: small;

  a {
    text-decoration: none;
    font-size: small;
  }
}

.signup {
  a {
    text-decoration: none;
  }
}

.small-font-cls {
  font-size: 0.675em;
  text-align: justify;
}

.small-err-font {
  li {
    font-size: small;
  }
}

.error-message a {
  color: inherit;
  font-size: inherit;
  color: #0d6efd;
}
