@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixin.scss";

.side-panel {
  max-width: 420px;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #fafafa;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 20px;
  pointer-events: auto;
  .title-container {
    margin-bottom: 20px;
  }

  .main-title {
    display: inline-block;
    font-size: 16px;
    font-weight: 100;
    line-height: 1.5em;
    font-size: 16px;
    font-family: $font-ericssonHilda-regular;
  }

  .close-button {
    border: none;
    background: none;
    font-size: 16px;
    color: #242424b3;
    cursor: pointer;

    margin-left: 110px;
  }

  .content {
    margin-top: 45px;
  }

  .sub-title {
    margin-left: 10px;
    color: #242424;
    font-family: $font-ericssonHilda-bold;
    font-size: 14px;
    text-rendering: optimizeLegibility;
  }

  .product-symbol {
    align-items: center;
    background: #fafafa;
    border: 1px solid #242424;
    border-radius: 3px;
    color: #242424;
    display: flex;
    height: 48px;
    justify-content: center;
    margin-right: 12px;
    width: 48px;
    margin-left: 13px;
    margin-top: 12px;
    margin-bottom: 8px;
  }

  .icon {
    height: 35px;
    font-style: normal;
    vertical-align: middle;
  }
  .label-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Ericsson Hilda", Helvetica, Arial, sans-serif;
    margin-top: 12px;
    text-rendering: optimizeLegibility;
  }

  .text-hover:hover {
    background: #dcdcdc;
    cursor: pointer;
    border-radius: 5px;
  }
  .text-hover {
    margin-top: 13px;
  }
}
