@font-face {
    font-family: 'EricssonHilda-Bold'; 
    src: url('./OTF/EricssonHilda-Bold.otf') format('otf'),
    url('./TTF_PowerPoint/EricssonHilda-Bold.ttf') format('ttf'),
    url('./WOFF/EricssonHilda-Bold.woff') format('woff'), 
    url('./WOFF2/EricssonHilda-Bold.woff2')  format('woff2'); 
}

@font-face {
    font-family: 'EricssonHilda-ExtraBold'; 
    src: url('./OTF/EricssonHilda-ExtraBold.otf') format('otf'),
    url('./TTF_PowerPoint/EricssonHilda-ExtraBold.ttf') format('ttf'),
    url('./WOFF/EricssonHilda-ExtraBold.woff') format('woff'), 
    url('./WOFF2/EricssonHilda-ExtraBold.woff2')  format('woff2'); 
}

@font-face {
    font-family: 'EricssonHilda-ExtraLight'; 
    src: url('./OTF/EricssonHilda-ExtraLight.otf') format('otf'),
    url('./TTF_PowerPoint/EricssonHilda-ExtraLight.ttf') format('ttf'),
    url('./WOFF/EricssonHilda-ExtraLight.woff') format('woff'), 
    url('./WOFF2/EricssonHilda-ExtraLight.woff2')  format('woff2'); 
}

@font-face {
    font-family: 'EricssonHilda-Light'; 
    src: url('./OTF/EricssonHilda-Light.otf') format('otf'),
    url('./TTF_PowerPoint/EricssonHilda-Light.ttf') format('ttf'),
    url('./WOFF/EricssonHilda-Light.woff') format('woff'), 
    url('./WOFF2/EricssonHilda-Light.woff2')  format('woff2'); 
}

@font-face {
    font-family: 'EricssonHilda-Medium'; 
    src: url('./OTF/EricssonHilda-Medium.otf') format('otf'),
    url('./TTF_PowerPoint/EricssonHilda-Medium.ttf') format('ttf'),
    url('./WOFF/EricssonHilda-Medium.woff') format('woff'), 
    url('./WOFF2/EricssonHilda-Medium.woff2')  format('woff2'); 
}

@font-face {
    font-family: 'EricssonHilda-Regular'; 
    src: url('./OTF/EricssonHilda-Regular.otf') format('otf'),
    url('./TTF_PowerPoint/EricssonHilda-Regular.ttf') format('ttf'),
    url('./WOFF/EricssonHilda-Regular.woff') format('woff'), 
    url('./WOFF2/EricssonHilda-Regular.woff2')  format('woff2'); 
}