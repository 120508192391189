@import "../../assets/scss/variables.scss";

.container1 {
  position: relative;
  width: 50%;
  max-width: 150px;
}

.image1 {
  display: block;
  width: 150px;
  height: 150px;
  border: 3px solid black;
  border-radius: 50%;
  // background-color: #feae30;
}

.overlay1 {
  position: absolute;
  // filter: brightness(0) invert(1);
  // filter: invert(74%) sepia(88%) saturate(1843%) hue-rotate(2deg)
  //   brightness(106%) contrast(92%);
  bottom: -35px;
  right: -36px;
  border-radius: 8px;
  border: 2px solid black;
  // background: rgb(0, 0, 0);
  // background: rgba(0, 0, 0, 0.3);
  background-color: rgb(255, 255, 255);
  // background: rgba(0, 0, 0, -0.5);
  /* Black see-through */

  width: 32px;
  transition: 0.5s ease;
  opacity: 1;
  color: white;
  padding: 3px;
  text-align: center;
  margin: 42px;
  font-family: $font-ericssonHilda-bold;
  cursor: pointer;
}
