// .step-wizard {
//     // background-color: #21d4fd;
//     // background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%);
//     // height: 100vh;
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

.step-wizard-list {
  // background: #fff;
  // box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  // color: #333;
  list-style-type: none;
  border-radius: 10px;
  display: flex;
  padding: 20px 10px;
  position: relative;
  z-index: 10;
}

.step-wizard-item {
  padding: 0 5px;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  // min-width: 170px;
  min-width: auto;
  position: relative;
}

.step-wizard-item + .step-wizard-item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 19px;
  background: #212529;
  width: 100%;
  height: 2px;
  transform: translateX(-50%);
  z-index: -10;
}

.progress-count {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  color: transparent;
}

.progress-count:after {
  content: "";
  height: 20px;
  width: 20px;
  background: #212529;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -10;
}

.progress-count:before {
  //     font-size: 8px;
  content: "";
  height: 5px;
  width: 11px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  position: absolute;
  left: 44%;
  top: 46%;
  transform: translate(-30%, -40%) rotate(-25deg);
  transform-origin: center center;
}

.progress-label {
  font-size: 7px;
  font-weight: 600;
  margin-top: 10px;
}

.progress-count:before,
.step-wizard-item .progress-count:before {
  display: none;
}

.step-wizard-item .progress-count:after {
  height: 8px;
  width: 8px;
}

.step-wizard-item .progress-label {
  opacity: 0.5;
}

.current-item .progress-count:after {
  background: #fff;
  border: 1px solid #212529;
  height: 20px;
  width: 20px;
}

.current-item .progress-count {
  color: #212529;
}

@media screen and (max-width: 450px) {
  .step-wizard-item {
    // min-width: 70px;
    // flex-grow: 0;
    padding: 0 0;
    width: 28px;
  }

  .step-wizard-list {
    padding: 20px 0px;
    justify-content: center;
  }

  .progress-label {
    display: none;
  }

  .progress-count {
    margin-left: -2px;
    width: 35px;
  }
}

@media screen and (max-width: 750px) {
  .step-wizard-item {
    padding: 0 0;
    // min-width: 70px;
    flex-grow: 0;
  }

  .progress-label {
    display: none;
  }
}

.step-wizard-item {
  cursor: pointer;
}

.current-item ~ .step-wizard-item {
  cursor: auto;
}

.step-wizard-item {
  &.selected {
    &.visibleSummary {
      cursor: auto;
    }
  }
}

.step-wizard-item.selected .progress-count:before,
.step-wizard-item.selected .progress-count:before {
  display: block;
}

.step-wizard-item.selected .progress-count:after {
  height: 20px;
  width: 20px;
}

.step-wizard-item.selected {
  cursor: pointer;
}

.current-item.selected .progress-count:before {
  display: none;
}

.selected.step-wizard-item .progress-label {
  opacity: 1;
}

body {
  counter-reset: my-sec-counter;

  .step-wizard-list.iJHA {
    .step-wizard-item {
      .progress-count {
        &::before {
          counter-increment: my-sec-counter;
          content: counter(my-sec-counter);
        }
      }

      &.current-item {
        .progress-count {
          &::before {
            height: 0;
            width: 18px;
            display: flex;
            justify-content: center;
            transform: translate(0, 0) rotate(0deg);
            left: 0;
            right: 0;
            margin: auto;
            border-left: 0;
            border-bottom: 0;
            top: 25%;
          }
        }
      }

      &.selected {
      }
    }
  }
}
