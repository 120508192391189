@import "../../assets/scss/variables.scss";

.product {
  font-size: 14px;
  font-family: $font-ericssonHilda-bold;
  color: $font-white-color !important;
  line-height: 2.5em;
  display: flex;
  align-items: center;
}

.nav-dropdown-dark-username {
  a {
    color: #d7d7d7 !important;

    &:hover {
      color: $font-white-color !important;
    }
  }
}

.profile-icon-header {
  /* line-height: 0.1; */
  align-items: center;
}

.icon-greencolor {
  margin-bottom: 2px;
  margin-right: 2px;
}

.titleheader {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
  margin-right: 8px;
  color: black;
  background-color: black;
}

@media (max-width: 385px) {
  .nav-dropdown-dark-username {
    width: 100px;
  }
}

@media (max-width: 358px) {
  .nav-dropdown-dark-username {
    width: 80px;
  }
}

@media (max-width: 338px) {
  .nav-dropdown-dark-username {
    width: 40px;
  }
}
@media (max-width: 298px) {
  .nav-dropdown-dark-username {
    width: 20px;
  }
}
.profile-icon-header{
  .appVersion{color: #fff; margin-right: 15px;}
  .nav-dropdown-dark-username{
    @media (max-width: 480px) {width: 43px;}
    #nav-dropdown-dark-username{
      @media (max-width: 480px) {width: 43px; overflow: hidden;}
    }
  }
 
}
